import React from "react";
import { AppContextProvider } from "./src/context/AppContext";
import Amplify from "aws-amplify";
import de from "./i18n/amplify/de.js";
import { Grommet } from "grommet";
import theme from "./src/components/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure({
  aws_cognito_region: "eu-central-1",
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:e0318379-0d03-4bca-8a20-ad5871885503",
  aws_user_pools_id: "eu-central-1_2LENJs63r",
  aws_user_pools_web_client_id: "1n6mckqeaqkdpd9dvssmptdkg3",
  aws_appsync_graphqlEndpoint:
    "https://vsmurzd5wfe3xjk73j5xzoxk64.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "us-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

Amplify.I18n.putVocabulariesForLanguage("de", de);

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <Grommet theme={theme}>{element}</Grommet>
    <ToastContainer />
  </AppContextProvider>
);
