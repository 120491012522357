import { Translations } from "@aws-amplify/ui-components";

const de = {
  [Translations.BACK_TO_SIGN_IN]: "Zurück",
  [Translations.CHANGE_PASSWORD_ACTION]: "Ändern",
  [Translations.CHANGE_PASSWORD]: "Passwort ändern",
  [Translations.CODE_LABEL]: "Bestätigungscode",
  [Translations.CODE_PLACEHOLDER]: "Code",
  [Translations.EMAIL_LABEL]: "E-Mail-Adresse *",
  [Translations.EMAIL_PLACEHOLDER]: "Ihre E-Mail",
  [Translations.FORGOT_PASSWORD_TEXT]: "Passwort vergessen?",
  [Translations.NEW_PASSWORD_LABEL]: "Neues Passwort",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Neues Passwort eingeben",
  [Translations.USERNAME_LABEL]: "E-Mail *",
  [Translations.USERNAME_PLACEHOLDER]: "Geben Sie Ihre E-Mail-Adresse ein",
  [Translations.PASSWORD_LABEL]: "Passwort *",
  [Translations.PASSWORD_PLACEHOLDER]: "Geben Sie Ihr Passwort ein",
  [Translations.RESET_PASSWORD_TEXT]: "Passwort zurücksetzen",
  [Translations.RESET_YOUR_PASSWORD]: "Passwort zurücksetzen",
  [Translations.SEND_CODE]: "Code senden",
  [Translations.SUBMIT]: "Senden",
  [Translations.SIGN_IN_ACTION]: "Anmelden",
  [Translations.SIGN_IN_HEADER_TEXT]: "Anmelden",
  [Translations.SIGN_IN_TEXT]: "Anmelden",
  [Translations.SIGN_OUT]: "Abmelden",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Sie haben bereits ein Konto?",
  [Translations.CREATE_ACCOUNT_TEXT]: "Konto erstellen",
  [Translations.SIGN_UP_HEADER_TEXT]: "Konto erstellen",
  [Translations.NO_ACCOUNT_TEXT]: "Kein Konto?",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Bestätigungscode",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Geben Sie Ihren Code ein",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Bestätigung",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Code verloren?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Code erneut senden",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Bestätigen",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Konto erstellen",
  [Translations.NAME_LABEL]: "Name",
  [Translations.NAME_PLACEHOLDER]: "Paul Becker",
  [Translations.DEFAULT_MSG]: "Authentifizierungsfehler",
  [Translations.EMPTY_USERNAME]: "E-Mail ist erforderlich",
  [Translations.INVALID_USERNAME]: "E-Mail ist ungültig",
  [Translations.EMPTY_PASSWORD]: "Passwort ist erforderlich",
  [Translations.EMPTY_CODE]: "Bestätigungscode ist erforderlich",
  [Translations.SIGN_UP_ERROR]: "Fehler bei der Kontoerstellung",
};

export default de;
